import {Component, OnInit} from '@angular/core';
import {NavController, NavParams, PopoverController} from "@ionic/angular";

@Component({
    selector: 'app-workorderdetails-popover',
    templateUrl: './workorderdetails-popover.component.html',
    styleUrls: ['./workorderdetails-popover.component.scss'],
})
export class WorkorderdetailsPopoverComponent implements OnInit {

    orderId = '';

    constructor(private navParams: NavParams, public navCtrl: NavController, public popoverController: PopoverController) {
    }

    ngOnInit() {
        this.orderId = this.navParams.data.orderId;
    }

    async openBreakDownHistory() {
        await this.dismissClick();
        await this.navCtrl.navigateForward('/menu/home/tabs/workorders/breakdown-history/' + this.orderId);
    }

    async openMaterials() {
        await this.dismissClick();
        await this.navCtrl.navigateForward('/menu/home/tabs/workorders/materials/' + this.orderId);
    }

    async openItems() {
        await this.dismissClick();
        await this.navCtrl.navigateForward('/menu/home/tabs/workorders/open-items/' + this.orderId);
    }

    async openPreviousOrders() {
        await this.dismissClick();
        await this.navCtrl.navigateForward('/menu/home/tabs/workorders/previous-pms/' + this.orderId);
    }

    async dismissClick() {
        await this.popoverController.dismiss();
    }
}