import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from "./service/auth-guard.service";

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'},
    {path: 'menu', /*canActivate: [AuthGuardService], */loadChildren: './pages/menu/menu.module#MenuPageModule'},
  { path: 'supervisor-count-list', loadChildren: './pages/supervisor-count-list/supervisor-count-list.module#SupervisorCountListPageModule' },
  { path: 'supervisor-workcenter-details', loadChildren: './pages/supervisor-workcenter-details/supervisor-workcenter-details.module#SupervisorWorkcenterDetailsPageModule' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
