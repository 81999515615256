import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {SimplePdfViewerComponent} from "simple-pdf-viewer";
import {File, IFile} from "@ionic-native/file/ngx";

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {

    @ViewChild(SimplePdfViewerComponent, {static: true}) private pdfViewer: SimplePdfViewerComponent;

    orderId = '';
    pdfUrl = '';
    message = '';
    buttonText = '';
    isPDFAvailable = true;
    messageText = '';

    constructor(public modalController: ModalController, private navParams: NavParams, private file: File) {
    }

    async ngOnInit() {
        this.orderId = this.navParams.data.orderId;
        this.pdfUrl = this.navParams.data.pdfUrl;
        this.message = this.navParams.data.message;
        this.buttonText = this.navParams.data.buttonText;
        this.messageText = this.navParams.data.messageText;
        if (this.message) {
            this.isPDFAvailable = false;
        }
        //this.pdfUrl = 'https://vsquaredapp-test.s3.us-east-2.amazonaws.com/10001745_1570516009690.pdf';
        //this.pdfUrl = '/testpdf';
        console.log(`PDFUrl : ${this.pdfUrl}`);
        console.log(`message : ${this.message}`);
        console.log(`isPDFAvailable : ${this.isPDFAvailable}`);

        if (this.pdfUrl.startsWith("http")) {
            await this.openUrl(this.pdfUrl);
        } else {
            const lastIndex = this.pdfUrl.lastIndexOf('/');
            const sourceDir = this.pdfUrl.substr(0, lastIndex);
            const sourceName = this.pdfUrl.substr(lastIndex);

            console.log(`PDFUrl sourceDir : ${sourceDir}`);
            console.log(`PDFUrl sourceName : ${sourceName}`);

            const dirEntry = await this.file.resolveDirectoryUrl(sourceDir);
            const local = await this.file.getFile(dirEntry, sourceName, {});

            local.file(file => {
                console.log(`PDFUrl file : ${file}`);
                this.openLocal(file);
            })
        }


        this.pdfViewer.onLoadComplete.subscribe({
            next: (event) => {
                console.log(`Load complete : ${event}`);
            }
        })

        this.pdfViewer.onError.subscribe({
            next: (event) => {
                console.log(`Error : ${JSON.stringify(event)}`);
            }
        })

        this.pdfViewer.onProgress.subscribe({
            next: (event) => {
                console.log(`progress : ${JSON.stringify(event)}`);
            }
        })
    }

    async dismissModal(event) {
        const modal = await this.modalController.getTop();
        if (modal) {
            modal.dismiss({});
        }
    }

    async accept() {
        const modal = await this.modalController.getTop();
        if (modal) {
            modal.dismiss({isOrderStarted: true});
        }
    }

    async openLocal(file: IFile) {
        console.log(`PDFUrl file : ${file}`);
        this.pdfViewer.openFile(file);
    }

    async openUrl(url) {
        console.log(`PDFUrl url : ${url}`);
        this.pdfViewer.openUrl(url);
    }
}
