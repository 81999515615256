import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { SimplePdfViewerComponent } from "simple-pdf-viewer";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-pdf-viewer-web",
  templateUrl: "./pdf-viewer-web.component.html",
  styleUrls: ["./pdf-viewer-web.component.scss"],
})
export class PdfViewerWebComponent implements OnInit {
  @ViewChild(SimplePdfViewerComponent, { static: true })
  private pdfViewer: SimplePdfViewerComponent;

  orderId = "";
  pdfUrl = "";
  message = "";
  buttonText = "";
  isPDFAvailable = true;
  messageText = "";
  urlSafe;

  constructor(
    public sanitizer: DomSanitizer,
    public modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.orderId = this.navParams.data.orderId;
    this.pdfUrl = this.navParams.data.pdfUrl;
    this.message = this.navParams.data.message;
    this.buttonText = this.navParams.data.buttonText;
    this.messageText = this.navParams.data.messageText;

    console.log(`PDFUrl orderId : ${this.orderId}`);
    console.log(`PDFUrl pdfUrl : ${this.pdfUrl}`);
    console.log(`PDFUrl message : ${this.message}`);
    console.log(`PDFUrl buttonText : ${this.buttonText}`);
    console.log(`PDFUrl isPDFAvailable : ${this.isPDFAvailable}`);
    console.log(`PDFUrl messageText : ${this.messageText}`);

    if (this.isPDFAvailable) {
      if (this.pdfUrl.startsWith("http")) {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.pdfUrl
        );
      }
    }
  }

  async dismissModal(event) {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss({});
    }
  }

  async accept() {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss({ isOrderStarted: true });
    }
  }
}
