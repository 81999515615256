import {Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";
import {AppPreferences} from "@ionic-native/app-preferences/ngx";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(public platform: Platform, public appPreferences: AppPreferences) {
    }

    async setValue(key, value) {
        if (this.platform.is('cordova')) {
            await this.appPreferences.store('dict', key, value);
        } else {
            window.localStorage.setItem(key, '' + value);
        }
    }

    async getValue(key) {
        if (this.platform.is('cordova')) {
            try {
                return this.appPreferences.fetch('dict', key);
            } catch (e) {
                return null;
            }
        } else {
            const value = window.localStorage.getItem(key);
            if (value === 'true' || value === 'false') {
                return value === 'true';
            }

            return value;
        }
    }
}
