import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalController, NavParams, Platform } from "@ionic/angular";
import { SimplePdfViewerComponent } from "simple-pdf-viewer";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";
import { PdfViewerWebComponent } from "../pdf-viewer-web/pdf-viewer-web.component";

@Component({
  selector: "app-doc-view",
  templateUrl: "./doc-view.component.html",
  styleUrls: ["./doc-view.component.scss"],
})
export class DocViewComponent implements OnInit {
  @ViewChild(SimplePdfViewerComponent, { static: true })
  private pdfViewer: SimplePdfViewerComponent;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public platform: Platform
  ) {}

  equipArr = [];
  maintainArr = [];
  maintainGenArr = [];
  equipGenArr = [];
  isCordova = false;

  ngOnInit() {
    this.isCordova = this.platform.is("cordova");
    this.equipArr = this.navParams.data.equipArr;
    this.maintainArr = this.navParams.data.maintainArr;
    this.maintainGenArr = this.navParams.data.maintainGenArr;
    this.equipGenArr = this.navParams.data.equipGenArr;
  }

  async dismissModal(event) {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss({});
      console.log(event, "DISMISSED");
    }
  }

  async openPDFFileModal(pdfUrl, messageText, buttonText) {
    console.log("PDF URL", pdfUrl);
    console.log("Message", messageText);
    var modal;
    if (this.isCordova) {
      console.log("MOBILE VIEW");
      modal = await this.modalController.create({
        component: PdfViewerComponent,
        cssClass: "modal-fullscreen",
        showBackdrop: true,
        componentProps: {
          pdfUrl: pdfUrl,
          buttonText: buttonText,
          messageText: messageText,
        },
      });
    } else {
      console.log("WEB VIEW");
      modal = await this.modalController.create({
        component: PdfViewerWebComponent,
        cssClass: "modal-fullscreen",
        showBackdrop: true,
        componentProps: {
          pdfUrl: pdfUrl,
          buttonText: buttonText,
          messageText: messageText,
        },
      });
    }
    return await modal.present();
  }
}
