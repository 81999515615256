import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthenticationService} from "./service/authentication.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authenticationService: AuthenticationService,
        private router: Router,
        public navCtrl: NavController
    ) {
        this.initializeApp();
    }

    count = 0;

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            /*this.authenticationService.authState.subscribe(state => {
                console.log(`I am here for testing`);
                this.count++;
                //this is hack as it was called twice
                if (this.count % 1 == 0) {
                    console.log(`state : ${state}`);
                    if (state) {
                        console.log('opening home page');
                        this.navCtrl.navigateRoot('/menu/home');
                    } else {
                        console.log('opening login page');
                        this.navCtrl.navigateRoot('login');
                    }
                }
            });*/
        });
    }
}
