import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {StorageService} from "./storage.service";
import {Platform} from "@ionic/angular";
import {AppPreferences} from "@ionic-native/app-preferences/ngx";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    authState = new BehaviorSubject(false);

    constructor(private router: Router, public storageService: StorageService, public platform: Platform,
                public appPreferences: AppPreferences) {
    }

    async isAuthenticated() {
        let isLogin = await this.storageService.getValue('isLogin');
        console.log(`isAuthenticated - ${isLogin}`);
        if (isLogin) {
            this.authState.next(true);
        } else {
            this.authState.next(false);
        }
        return this.authState.value;
    }
}