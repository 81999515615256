import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams, ToastController} from "@ionic/angular";
import {getRepository, Repository} from "typeorm";
import {Order} from "../../entity/order";
import * as moment from "moment-timezone";
import {OrderOperations} from "../../entity/order-operations";

@Component({
    selector: 'app-close-order-modal',
    templateUrl: './close-order-modal.component.html',
    styleUrls: ['./close-order-modal.component.scss'],
})
export class CloseOrderModalComponent implements OnInit {

    orderId = '';
    commentLength = 0;
    closeOrderData = {
        confirmedForProduction: true,
        toolsReturned: false,
        areaCleanedUp: false,
        requireFollowUp: false,
        comment: ''
    };

    constructor(public modalController: ModalController, private navParams: NavParams, public toastController: ToastController) {
    }

    async ngOnInit() {
        this.orderId = this.navParams.data.orderId;
        const orderRepository = getRepository('order') as Repository<Order>;
        const dbOrder = await orderRepository.findOne({'ORDERID': this.orderId});
        this.closeOrderData.confirmedForProduction = dbOrder.CNFPRODUCTION;
        this.closeOrderData.toolsReturned = dbOrder.TOOLSRETURNED;
        this.closeOrderData.areaCleanedUp = dbOrder.WACLEANEDUP;
        this.closeOrderData.requireFollowUp = dbOrder.REQUIREFOLLOWUP;
        this.closeOrderData.comment = dbOrder.ORDERCOMMENT;
    }

    async dismissModal(event, isOrderClosed, requireFollowUp) {
        const modal = await this.modalController.getTop();
        if (modal) {
            modal.dismiss({isOrderClosed, requireFollowUp});
        }
    }

    async closeOrder() {
        console.log(JSON.stringify(this.closeOrderData));
        const orderRepository = getRepository('order') as Repository<Order>;
        const dbOrder = await orderRepository.findOne({'ORDERID': this.orderId});
        dbOrder.CNFPRODUCTION = this.closeOrderData.confirmedForProduction;
        dbOrder.TOOLSRETURNED = this.closeOrderData.toolsReturned;
        dbOrder.WACLEANEDUP = this.closeOrderData.areaCleanedUp;
        dbOrder.REQUIREFOLLOWUP = this.closeOrderData.requireFollowUp;
        dbOrder.ORDERCOMMENT = this.closeOrderData.comment;
        dbOrder.SYSTEMSTATUS = 'TECO';
        dbOrder.STR_ENDDATE = moment(new Date()).tz(moment.tz.guess()).format('YYYYMMDD');
        dbOrder.STR_ENDTIME = moment(new Date()).tz(moment.tz.guess()).format('HHmmss');
        dbOrder.isSynced = false;
        dbOrder.isClosed = true;
        await orderRepository.save(dbOrder);
        await this.dismissModal(null, true, this.closeOrderData.requireFollowUp);
    }

    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    onCommentTextChnage(event) {
        const eventTarget = event.target;
        let value = eventTarget.value;
        value = '' + value;
        this.commentLength = value.length;
    }
}
